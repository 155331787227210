import { Carousel, CarouselSlide } from "core/components/carousel";
import { ParameterCardV2 } from "./parameterCardV2";
import _ from "lodash";
import { ConsumptionCard } from "./consumptionCard";

const ConsumptionCarouselSlide = ({ date, consumption = "NA" }) => {
  return (
    <CarouselSlide>
      <ConsumptionCard date={date} consumption={consumption} />
    </CarouselSlide>
  );
};

export const ConsumptionCarouselCard = ({ consumptionDetails }) => {
  if (consumptionDetails == undefined || consumptionDetails.length == 0) {
    return "";
  }
  console.log(consumptionDetails);
  const carouselSlides = [];
  const counter = 1;
  _.forEach(consumptionDetails, (detail) => {
    const { date, consumption, epoch } = detail;
    // console.log("consumption in card:", date, consumption, epoch);

    carouselSlides.push(
      <ConsumptionCarouselSlide
        key={epoch}
        date={epoch}
        consumption={consumption}
      />
    );
  });

  let carousels = "";
  // console.log(carouselSlides);
  if (carouselSlides.length > 1) {
    return <Carousel>{carouselSlides}</Carousel>;
  } else {
    return carouselSlides[0];
  }
  return carousels;
};

import _ from "lodash";
import Joi from "joi";
import { toast } from "react-toastify";
import { generateReport } from "./reportService";
import { useLoading } from "core/hooks/loadingHook";
import { useSubmitForm } from "core/hooks/submitFormHook";
import { downloadReport } from "core/utility/util";
import { FormCard } from "core/cards/formCard";
import { EditTextField } from "core/components/inputWithAccessControl";
import { SelectContainer } from "core/components/inputWithAccessControl";
import monthList from "./month.json";
import { addDefaultOption } from "core/utility/util";
import { formatArrayToOptions } from "core/utility/util";

export const MtlLastReadingReport = () => {
    const { loaderContainer, startLoading, stopLoading } = useLoading();


    const monthOption = addDefaultOption(formatArrayToOptions(monthList));

    const defaultInputs = {};
    const submitCallback = async () => {
        startLoading();
        const { month, year } = inputs;
        const body = {
            templateName: "lorawan_mtl_monthly_consumption",
            reportName: "Monthly_Consumption_Report",
            exportFormat: "xlsx",
            input: {
                month: month,
                year: year
            },
        };
        try {
            const response = await generateReport(body);
            let fileName = response.headers["x-blob-file"];
            downloadReport(fileName, response);
            toast.success("Report generated successfully");
            stopLoading();
        } catch (error) {
            console.log(error);
            toast.error("Error while generating report");
            stopLoading();
        }
    };
    const schema = Joi.object({
        month: Joi.string().label("Month").required(),
        year: Joi.number().label("Year").positive().required(),

    });
    const { inputs, errors, handleInputChange, handleSubmit, resetInput } =
        useSubmitForm(schema, submitCallback, defaultInputs);
    const accessToEdit = true;
    return (
        <div className="card mx-2 my-2">
            <FormCard
                formName={"Zone wise Consumption Report"}
                onSubmit={handleSubmit}
                submitAccess={true}
            >
                <div className="columns is-multiline">

                    <div className="column is-one-third">
                        <EditTextField
                            identifier="year"
                            labelName="Year"
                            step={5}
                            editAccess={accessToEdit}
                            handleInputChange={handleInputChange}
                            inputs={inputs}
                            errors={errors}
                        />
                    </div>
                    {/* /.column */}

                    <div className="column is-one-third">
                        <SelectContainer
                            identifier="month"
                            labelName="Month"
                            handleInputChange={handleInputChange}
                            inputs={inputs}
                            errors={errors}
                            editAccess={true}
                            options={monthOption}
                        />
                    </div>
                    {/* /.column */}

                </div>
                {/* /.columns */}
            </FormCard>
            {loaderContainer}
        </div>
    );
};
